import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const Bloom = () => {
  useEffect(() => {
    navigate('https://mustafazc.com/BloomWithHaneen.apk')
  }, [])
  return (
    <object
      data="https://mustafazc.com/BloomWithHaneen.pdf"
      type="application/pdf"
      width="100%"
      height="100%"
      style={{ minHeight: '100vh' }}
    >
      <p>
        <a href="https://mustafazc.com/BloomWithHaneen.pdf">
          Bloom With Haneen PDF
        </a>
      </p>
    </object>
  )
}

export default Bloom
